import styles from "./result.module.scss";
import {
  Modal,
  QRCode,
  Button,
  Alert,
  message,
  Input,
  Spin,
  Image
} from 'antd'
import { useState, useEffect, useRef, useCallback } from "react";
import error from "../../assets/error.png";
import success from "../../assets/success.png";
import { baseURL, get, post } from "../../axios/axios";
import { Checkbox, Carousel } from "antd";
import { NotificationOutlined } from "@ant-design/icons";
import "../Billing.scss";
import { isMobile, isWexin } from "../../common/const";
import { newConstructStore } from "./newStore";
import { handleDownLoad } from "../../Service/utils";
import useStreamRequest from "../../hooks/useStreamRequest";
import { loginInstance } from "../../Service/login";
import warning  from '../../assets/warning.png'

export function EditResult({ paper, updateOrderNo }) {
  const [showPayModal, setShowPayModal] = useState(false);
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [redeemCode, setRedeemCode] = useState("");
  const [timerId, setTimerId] = useState("");
  const [payResult, setPayResult] = useState(false);
  const payInfoRef = useRef({});
  const [showPayResult, setShowPayResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [demoList, setDemoList] = useState([]);
  const submitPay = async () => {
    try {
      if (!checked) {
        message.warning("请先阅读并勾选服务条款");
        return;
      }
      if (loading) {
        return;
      }
      if (isWexin && isMobile) {
        const res = await get(`/member/wechat/a/url?orderNo=${paper.orderNo}`);
        window.location.href = res;
        return;
      }

      setLoading(true);
      const client = isMobile ? "H5" : "PC";
      const res = await post("/member/payment/pay", {
        orderNo: paper.orderNo,
        client: client,
      });

      if (client === "H5") {
        window.location.href = res.data.mwebUrl;
      } else {
        payInfoRef.current = {
          ...(res.data || {}),
        };
        setShowPayModal(true);
        checkPay();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const showCodeModal = async (e) => {
    e.stopPropagation();
    setShowRedeemModal(true);
  };

  const submitRedeemCode = async () => {
    try {
      const res = await post("/member/payment/redeemCode", {
        orderNo: paper.orderNo,
        code: redeemCode,
      });
      console.log("res", res);
      if (res && res.code === 0) {
        message.info("正在加速生成中，预计需要10分钟");
        setShowRedeemModal(false);
        setPayResult(true);
      } else {
        const msg = res && res.msg;
        message.error(
          msg || "请检查您输入的兑换码是否正确，如果错误请重新输入。"
        );
      }
    } catch (error) {
      console.error(error);
      // message.error("请检查您输入的兑换码是否正确，如果错误请重新输入。");
    }
  };

  const onCancel = () => {
    window.clearTimeout(timerId);
    payInfoRef.current = {};
    setShowPayResult(false);
    setShowPayModal(false);
  };

  const toRePay = async () => {
    setShowPayResult(false);
    const res = await post("/member/payment/pay", {
      orderNo: paper.orderNo,
      client: "PC",
    });

    payInfoRef.current = res.data || {};
  };

  const firstCheckPay = async () => {
    // const res = await get("/member/payment/pay/result/" + data.orderNo);
    // if (res && res.data) {
    //   setPayResult(res.data.code === 0);
    // }
  };

  const innerUpOrderNo = async (
    currentTableCheck = tableCheck,
    currentGongshiCheck = gongshiCheck,
    currentDaimaCheck = daimaCheck,
    currentTuCheck = tuCheck
  ) => {
    let customReferenceDocument = undefined;
    const reference = newConstructStore.stepWenxian.selectIdxList
      .map((id) => {
        if (id > 0) {
          return newConstructStore.stepWenxian.wenxianList[id].id;
        } else {
          customReferenceDocument =
            newConstructStore.stepWenxian.wenxianList[0];
          return "";
        }
      })
      .filter(Boolean)
      .join(",");

    let ss = getSs(currentTableCheck, currentGongshiCheck, currentDaimaCheck, currentTuCheck);
    const res = await post("/ai/paper/new/w", {
      majorName: newConstructStore.step1.majorName,
      keywords: newConstructStore.step1.keywords.join(","),
      paperTitle:
        newConstructStore.step2.titles[newConstructStore.step2.selectIdx],
      paperAbstracts:
        newConstructStore.step4.titles[newConstructStore.step4.selectIdx],
      paperNewOutline: newConstructStore.step4.outlines,
      reference,
      customReferenceDocument,
      ss,
      orderNo: paper.orderNo,
      type: newConstructStore.step1.type,
    });
    updateOrderNo(res.data.orderNo);
  };

  useEffect(() => {
    (async () => {
      firstCheckPay();
      getPayDemo();
    })();
  }, []);

  const checkPay = async () => {
    if (timerId) {
      window.clearTimeout(timerId);
    }

    try {
      const res = await get("/member/payment/pay/result/" + paper.orderNo);
      if (res && res.data && res.data.code === 0) {
        setShowPayResult(true);
        setPayResult(res.data.code === 0);

        setTimeout(() => {
          if (res.data.code === 0) {
            onCancel();
          }
        }, 1000);
      } else {
        const id = window.setTimeout(() => {
          checkPay();
        }, 1000);
        setTimerId(id);
      }
    } catch (err) {
      console.error(err);
    }
  };

  function openUrl(url) {
    let a = document.createElement("a");
    a.target = "_blank";
    a.href = url;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
  }

  const exportPaper = async () => {
    const { data, msg } = await get(`/ai/paper/status/${paper.orderNo}`);
    if (data) {
      message.success("论文下载完成");
      handleDownLoad(
        "https://cqingyoung.com/ai/word/paper/export/" + paper.orderNo
      );
      return;
    }

    if (data === null) {
      message.error(msg || "查询订单失败，请联系客服");
    }

    if (data === false) {
      message.info("正在加速生成中，预计需要10分钟");
    }
  };

  async function getPayDemo() {
    const { data } = await get("/ai/paper/r");
    setDemoList(data);
  }

  const [checked, setChecked] = useState(false);
  const [tuCheck, setTuCheck] = useState(
    newConstructStore.step1.autoLightTypes?.includes("DIAGRAM")
  );
  const [tableCheck, setTableCheck] = useState(
    newConstructStore.step1.autoLightTypes?.includes("TABLE")
  );
  const [gongshiCheck, setGongshiCheck] = useState(
    newConstructStore.step1.autoLightTypes?.includes("FORMULA")
  );
  const [daimaCheck, setDaimaCheck] = useState(
    newConstructStore.step1.autoLightTypes?.includes("CODE")
  );

  // 1是表格 2是公式 3是图 4是代码
  function getShowInfo() {
    let paperPrice = paper.paperItem ? +paper.paperItem.price : 0;
    let tableCount = 0;
    let gongshiCount = 0;
    let tuCount = 0;
    let daimaCount = 0;
    let professionalPrice = 0;
    let allPrice = paper.paperItem ? +paper.paperItem.price : 0;
    if (tableCheck) {
      const tables =
        newConstructStore.extraTypes.filter((it) => it.id === 1) || {};
      if (tables.length) {
        tableCount = tables.length;
        professionalPrice += tables.reduce((acc, cur) => acc + cur.price, 0);
        allPrice += tables.reduce((acc, cur) => acc + cur.price, 0);
      }
    }

    if (gongshiCheck) {
      const gongshi =
        newConstructStore.extraTypes.filter((it) => it.id === 2) || {};
      if (gongshi.length) {
        gongshiCount = gongshi.length;
        professionalPrice += gongshi.reduce((acc, cur) => acc + cur.price, 0);
        allPrice += gongshi.reduce((acc, cur) => acc + cur.price, 0);
      }
    }

    if (tuCheck) {
      const tu = newConstructStore.extraTypes.filter((it) => it.id === 3) || {};
      if (tu.length) {
        tuCount = tu.length;
        professionalPrice += tu.reduce((acc, cur) => acc + cur.price, 0);
        allPrice += tu.reduce((acc, cur) => acc + cur.price, 0);
      }
    }

    if (daimaCheck) {
      const daima =
        newConstructStore.extraTypes.filter((it) => it.id === 4) || {};
      if (daima.length) {
        daimaCount = daima.length;
        professionalPrice += daima.reduce((acc, cur) => acc + cur.price, 0);
        allPrice += daima.reduce((acc, cur) => acc + cur.price, 0);
      }
    }

    return {
      paperPrice,
      allPrice,
      tableCount,
      gongshiCount,
      tuCount,
      daimaCount,
      professionalPrice,
    };
  }

  function getLv(table, gongshi) {
    if (table && gongshi) {
      return 3;
    }

    if (table && !gongshi) {
      return 1;
    }
    if (!table && gongshi) {
      return 2;
    }
    return 0;
  }

  /**
   * 获取选中类型的字符串
   * @param {boolean} tableCheck - 表格复选框状态
   * @param {boolean} gongshiCheck - 公式复选框状态
   * @param {boolean} daimaCheck - 代码复选框状态
   * @param {boolean} tuCheck - 图复选框状态
   * @returns {string} - 返回类型字符串，多个类型用逗号分隔，都未选中返回"0"
   */
  const getSs = (tableCheck, gongshiCheck, daimaCheck, tuCheck) => {
    // 存储选中的类型
    const selectedTypes = [];

    // 检查每个选项的状态并添加对应的类型
    if (tableCheck) selectedTypes.push("1");
    if (gongshiCheck) selectedTypes.push("2");
    if (tuCheck) selectedTypes.push("3");
    if (daimaCheck) selectedTypes.push("4");

    // 如果没有选中任何类型，返回"0"
    if (selectedTypes.length === 0) {
      return "0";
    }

    // 返回用逗号连接的类型字符串
    return selectedTypes.join(",");
  };

  const [extraLoading, setExtraLoading] = useState(false);

  async function onExtraCheckChange(type, checked) {
    try {
      setExtraLoading(true);

      // 使用新的状态值
      let newTableCheck = tableCheck;
      let newGongshiCheck = gongshiCheck;
      let newDaimaCheck = daimaCheck;
      let newTuCheck = tuCheck;

      // 更新对应的状态
      if (type === "table") {
        newTableCheck = !!checked;
        setTableCheck(newTableCheck);
      } else if (type === "gongshi") {
        newGongshiCheck = !!checked;
        setGongshiCheck(newGongshiCheck);
      } else if (type === "daima") {
        newDaimaCheck = !!checked;
        setDaimaCheck(newDaimaCheck);
      } else if (type === "tu") {
        newTuCheck = !!checked;
        setTuCheck(newTuCheck);
      }

      // 使用新的状态值调用函数
      await innerUpOrderNo(newTableCheck, newGongshiCheck, newDaimaCheck,
        newTuCheck);
    } catch (error) {
      if (type === "table") {
        setTableCheck(!checked ? true : false);
      } else if (type === "gongshi") {
        setGongshiCheck(!checked ? true : false);
      } else if (type === "daima") {
        setDaimaCheck(!checked ? true : false);
      } else if (type === "tu") {
        setTuCheck(!checked ? true : false);
      }
    } finally {
      setExtraLoading(false);
    }
  }

  const auth = loginInstance.getHeaders();
  const authHeaders = auth ? { Authorization: auth } : {};

  const [enAbstracts, setEnAbstracts] = useState("");
  const handleMessage = useCallback((data) => {
    if (data.chatToken && data.chatToken !== "[DONE]") {
      // 解码 Unicode 编码的字符
      const decodedToken = JSON.parse(`"${data.chatToken}"`);
      setEnAbstracts((prev) => prev + decodedToken);
    }
  }, []);

  const handleError = useCallback((error) => {
    console.error("Stream error:", error);
  }, []);

  const [yinyan, setYinyan] = useState("");
  const handleYinyanMessage = useCallback((data) => {
    if (data.chatToken && data.chatToken !== "[DONE]") {
      const decodedToken = JSON.parse(`"${data.chatToken}"`);
      setYinyan((prev) => prev + decodedToken);
    }
  }, []);

  const handleYinyanError = useCallback((error) => {
    console.error("Stream error:", error);
  }, []);
  const handleYinyanFinish = useCallback(() => {
    console.log("Stream completed");
  }, []);
  const {
    streamRequest: yinyanStreamRequest,
    loading: yinyanStreamLoading,
    yinyansteamError,
  } = useStreamRequest({
    url: baseURL + "/ai/paper/gen/content",
    headers: {
      ...authHeaders,
    },
    onMessage: handleYinyanMessage,
    onError: handleYinyanError,
    onFinish: handleYinyanFinish,
  });

  const handleFinish = useCallback(() => {
    console.log("English abstract stream completed");
    // 触发第二个请求
    yinyanStreamRequest({
      majorName: newConstructStore.step1.majorName,
      keywords: newConstructStore.step1.keywords.join(","),
      paperTitle:
        newConstructStore.step2.titles[newConstructStore.step2.selectIdx],
      paperAbstracts:
        newConstructStore.step4.titles[newConstructStore.step4.selectIdx],
      orderNo: paper.orderNo,
    });
  }, [yinyanStreamRequest]);

  const {
    streamRequest: enStreamRequest,
    loading: enStreamLoading,
    enSteamError,
  } = useStreamRequest({
    url: baseURL + "/ai/paper/gen/en/abstracts",
    headers: {
      ...authHeaders,
    },
    onMessage: handleMessage,
    onError: handleError,
    onFinish: handleFinish,
  });

  // 如果需要监控加载状态
  const isLoading = enStreamLoading || yinyanStreamLoading;

  // 如果需要监控错误状态
  const error = enSteamError || yinyansteamError;
  // 添加初始化请求逻辑
  useEffect(() => {
    const initRequests = async () => {
      try {
        // 发起第一个请求
        await enStreamRequest({
          majorName: newConstructStore.step1.majorName,
          keywords: newConstructStore.step1.keywords.join(","),
          paperTitle:
            newConstructStore.step2.titles[newConstructStore.step2.selectIdx],
          paperAbstracts:
            newConstructStore.step4.titles[newConstructStore.step4.selectIdx],
          orderNo: paper.orderNo,
        });
        // 注意：第二个请求会在 handleFinish 中自动触发
      } catch (error) {
        console.error("初始化请求失败:", error);
      }
    };
    initRequests();
  }, []); // 空依赖数组，仅在组件挂载时执行一次

  return (
    <div className={styles.result}>
      <div className={styles.resultPage}>
        <div className={styles.pageTitle}>
          {paper.paperItem && paper.paperItem.remark}
        </div>
        <div>
          {/*{paper.list &&*/}
          {/*  paper.list.map((item, idx) => (*/}
          {/*    <div key={idx}>*/}
          {/*      <div className={styles.chapterTitle}>{item.chapter}</div>*/}
          {/*      <div className={styles.subHeading}>{item.subheadings}</div>*/}
          {/*      <div className={styles.content}>{item.content}</div>*/}
          {/*    </div>*/}
          {/*  ))}*/}
          {/*摘要*/}
          <h2
            style={{
              textAlign: 'center',
              marginTop: '32px',
              marginBottom: '32px',
              fontWeight: 'bold'
            }}
          >
            摘要
          </h2>
          <div className={styles.content}>
            {newConstructStore.step4.titles[newConstructStore.step4.selectIdx]}
          </div>
          {/* 英文摘要 */}
          <h2
            style={{
              textAlign: 'center',
              marginTop: '32px',
              marginBottom: '32px',
              fontWeight: 'bold'
            }}
          >
            英文摘要
          </h2>
          <div className={'mt40'}></div>
          <div className={styles.content}>{enAbstracts}</div>
          {/* 引言 */}
          <h2
            style={{
              textAlign: 'center',
              marginTop: '32px',
              marginBottom: '32px',
              fontWeight: 'bold'
            }}
          >
            一、引言
          </h2>
          <div className={styles.content}>{yinyan}</div>
          {isLoading ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                height: '100px'
              }}
            >
              <Spin/>
            </div>
          ) : null}
        </div>
        <div className={styles.pay}>
          <div className={styles.hideTop}></div>
          <div className={styles.payContent}>
            {payResult ? (
              <div onClick={exportPaper} className={styles.payBtn}>
                下载论文
              </div>
            ) : (
              <div className="flex" style={{ flexDirection: "column" }}>
                <Spin spinning={extraLoading}>
                  <Button
                    type="primary"
                    loading={loading || extraLoading}
                    className={styles.payBtn}
                    onClick={submitPay}
                  >
                    <div>￥{getShowInfo().allPrice || ""}解锁全篇</div>
                  </Button>
                  {paper?.paperItem?.id === 1 && (
                    <div className={styles.extra}>
                      <Checkbox
                        checked={tuCheck}
                        onChange={(e) =>
                          onExtraCheckChange("tu", e.target.checked)
                        }
                      >
                        <b style={{ color: "#24be58" }}>需要图</b>
                      </Checkbox>
                      <Checkbox
                        checked={tableCheck}
                        onChange={(e) =>
                          onExtraCheckChange("table", e.target.checked)
                        }
                      >
                        <b style={{ color: "#24be58" }}>需要表格</b>
                      </Checkbox>
                      <Checkbox
                        checked={gongshiCheck}
                        onChange={(e) =>
                          onExtraCheckChange("gongshi", e.target.checked)
                        }
                      >
                        <b style={{ color: "#24be58" }}>需要公式</b>
                      </Checkbox>
                      <Checkbox
                        checked={daimaCheck}
                        onChange={(e) =>
                          onExtraCheckChange("daima", e.target.checked)
                        }
                      >
                        <b style={{ color: "#24be58" }}>需要代码</b>
                      </Checkbox>
                    </div>
                  )}
                </Spin>

                <div className={styles.payTip}>
                  <Checkbox
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                  >
                    <span style={{ marginLeft: "4px", color: "#666" }}>
                      请先阅读并同意
                      <a
                        style={{ color: "#666" }}
                        className={styles.fuwuLink}
                        href={`${window.location.protocol}//${window.location.host}/agreement`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        《服务条款》
                      </a>
                    </span>
                  </Checkbox>
                  <div
                    style={{
                      color: "#999",
                      fontSize: "12px",
                      marginTop: "2px",
                    }}
                    onClick={showCodeModal}
                  >
                    我有兑换码
                  </div>
                </div>
              </div>
            ) }
          </div>

          {demoList && demoList.length && (
            <div className={styles.demoList}>
              <NotificationOutlined style={{ color: "rgb(36, 190, 88)" }} />
              <Carousel
                style={{ width: "300px", margin: "0 0 0 10px" }}
                dotPosition="left"
                autoplay={true}
                autoplaySpeed={1000}
              >
                {demoList.map((item, idx) => {
                  return <div key={idx}>{item}</div>;
                })}
              </Carousel>
            </div>
          )}
        </div>
      </div>

      <Modal
        open={showPayModal}
        width={550}
        footer={null}
        onCancel={onCancel}
        maskClosable={false}
        title={'解锁全文'}
        className="p0-ant-modal"
      >
        {showPayResult ? (
          <div className="pay-result">
            <div
              className="result"
              style={payResult ? { padding: "16px 0 20px 0" } : {}}
            >
              <p className="tips">
                {payResult ? "恭喜您，支付成功" : "很遗憾，支付失败"}
              </p>
              <img
                src={payResult ? success : error}
                className="image"
                style={payResult ? { margin: "16px 0 20px 0" } : {}}
              />
              {!payResult ? (
                <Button className="action_btn" onClick={toRePay}>
                  <span>重新支付</span>
                </Button>
              ) : (
                <Button onClick={onCancel}>
                  <span>完成</span>
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className="w-pay">
            <div className={'w-pay-alert'}>
              <Image src={warning} width={16}></Image>
              <span className={'text-[#F7941D] text-xs ml-[6px]'}>付款成功前请不要关闭此页面。如付款后页面没有更新，请根据您的情况点击下方按钮</span>
            </div>
            {/*<Alert*/}
            {/*  message="付款成功前请不要关闭此页面。如付款后页面没有更新，请根据您的情况点击下方按钮"*/}
            {/*  type="warning"*/}
            {/*  showIcon*/}
            {/*  className="w-pay-alert"*/}
            {/*/>*/}
            <div className="title">请使用微信扫一扫</div>
            <p className="order">订单号：{paper.orderNo}</p>
            <div className="qr-code">
              {/*<QRCode value={payInfoRef.current.codeUrl} />*/}
              <QRCode  size={174} value={payInfoRef.current.codeUrl} />
            </div>
            <p className="cost-tips">
               <span className="text">支付金额</span>
              <span className="value">¥{getShowInfo().allPrice || ""}</span>
            </p>
            {/*<p>*/}
            {/*  论文金额：<span className="unit">¥</span>{getShowInfo().paperPrice}元*/}
            {/*</p>*/}
            {/*<p>专业内容：{*/}
            {/*  getShowInfo().tableCount ? (*/}
            {/*    <span>*/}
            {/*  [数据(表)x{getShowInfo().tableCount}]*/}
            {/*    </span>*/}
            {/*  ) : null*/}
            {/*}*/}
            {/*  {*/}
            {/*    getShowInfo().tuCount ? (*/}
            {/*      <span>*/}
            {/*  [图x(表)x{getShowInfo().tuCount}]*/}
            {/*    </span>*/}
            {/*    ) : null*/}
            {/*  }*/}
            {/*  {*/}
            {/*    getShowInfo().gongshiCount ? (*/}
            {/*      <span>*/}
            {/*  [公式xx{getShowInfo().gongshiCount}]*/}
            {/*    </span>*/}
            {/*    ) : null*/}
            {/*  }*/}
            {/*  {*/}
            {/*    getShowInfo().daimaCount ? (*/}
            {/*      <span>*/}
            {/*  [代码段x{getShowInfo().daimaCount}]*/}
            {/*    </span>*/}
            {/*    ) : null*/}
            {/*  }*/}
            {/*  {*/}
            {/*    getShowInfo().professionalPrice ? (*/}
            {/*      <span>*/}
            {/*        &nbsp;*/}
            {/*      <span*/}
            {/*        className="unit">¥</span>{getShowInfo().professionalPrice}元*/}
            {/*        </span>*/}
            {/*    ) : null*/}
            {/*  }</p>*/}
            <div className="button-group">
              <Button type="primary" onClick={checkPay}>
                <span>付款成功</span>
              </Button>
            </div>
            <p className="tips">如遇支付问题，请联系客服微信</p>
          </div>
        )}
      </Modal>

      <Modal
        open={showRedeemModal}
        width={790}
        footer={null}
        onCancel={() => setShowRedeemModal(false)}
        maskClosable={false}
        title="请输入兑换码"
      >
        <Input
          style={{ marginTop: "16px" }}
          placeholder="请输入兑换码"
          value={redeemCode}
          onChange={(e) => setRedeemCode(e.target.value)}
        />
        <div
          style={{ marginTop: "20px", justifyContent: "flex-end" }}
          className="flex"
        >
          <Button type="primary" onClick={submitRedeemCode}>
            确定
          </Button>
        </div>
      </Modal>
    </div>
  );
}
