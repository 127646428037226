import { makeAutoObservable } from "mobx";
import { sleep } from "../../helper";
import { post, get } from "../../axios/axios";
import { uniqueId } from "lodash";
import { message, Modal } from "antd";
import nzhcn from "nzh/cn";
class NewConstructStore {
  curStep = 3;
  nextLoading = false;
  ll = 0;
  steps = [
    {
      title: "灵感",
    },
    {
      title: "标题",
    },
    {
      title: "大纲",
    },
    {
      title: "开头",
      description: "可跳过",
    },
  ];
  step1 = {
    majorName: "123",
    keywords: [],
    canSubmit: false,
    ls: false, // 是否需要开启参考文献
    autoLightTypes: [],
  };
  step2 = {
    selectIdx: -1,
    focusIdx: -1,
    titles: [
      "",
      "人工智能:未来已来,我们如何应对?",
      "人工智能时代,如何让机器像人一样学习?",
    ],
  };
  stepWenxian = {
    selectIdxList: [],
    wenxianList: [""],
  };
  step3 = {
    focusIdx: -1,
    menus: [
      {
        id: "1",
        chapter: "一、研究背景与意义",
        subheadings: [
          "1.1 人工智能与医疗行业的结合",
          "1.1.1 医疗行业现状与人工智能应用前景",
        ],
      },
      {
        id: "11",
        chapter: "二、相关研究综述",
        subheadings: [
          "2.1 医疗领域人工智能发展综述",
          "2.1.1 医疗图像识别",
          "2.1.2 医疗语音识别",
          "2.1.3 医疗数据分析",
        ],
      },
      {
        id: "111",
        chapter: "三、人工智能辅助医疗诊断系统的设计",
        subheadings: [
          "3.1 系统整体架构设计",
          "3.1.1 数据获取与处理模块",
          "3.1.2 患者病例分析与诊断模块",
          "3.1.3 可视化展示模块",
        ],
      },
      {
        id: "122",
        chapter: "四、关键技术研究",
        subheadings: [
          "4.1 深度学习及其在医疗领域的应用",
          "4.1.1 神经网络与深度学习原理",
          "4.1.2 医学影像数据分析模型构建",
          "4.1.3 医学语音信号识别算法研究",
        ],
      },
      {
        id: "3231",
        chapter: "五、系统实现与性能测试",
        subheadings: [
          "5.1 系统开发与实现",
          "5.1.1 系统硬件和软件平台的选择",
          "5.1.2 系统功能测试和性能优化研究",
        ],
      },
      {
        id: "11232",
        chapter: "六、结论与展望",
        subheadings: ["6.1 研究成果与贡献", "6.2 系统局限性及未来研究方向"],
      },
    ],
  };
  step4 = {
    outlines: [],
    selectIdx: -1,
    focusIdx: -1,
    titles: [
      "人工智能:未来已来,我们如何应对?",
      "人工智能时代,如何让机器像人一样学习?",
    ],
  };

  paperTypes = [];
  extraTypes = [];

  constructor() {
    makeAutoObservable(this);
    this.reset();
    this.getTypeList();
    this.getExtraList();
  }
  async getTypeList() {
    const { data } = await get("/ai/paper/type/list");
    this.paperTypes = data.map((item) => ({
      value: item.id,
      label: item.remark,
      ...item,
    }));
  }

  async getExtraList() {
    const { data } = await get("/ai/paper/type/s/list");
    this.extraTypes = data.map((item) => ({
      value: item.id,
      label: item.remark,
      ...item,
    }));
  }
  reset() {
    this.curStep = 0;
    this.nextLoading = false;
    this.steps = [
      {
        title: "灵感",
      },
      {
        title: "标题",
      },
      {
        title: "文献",
      },
      {
        title: "摘要",
      },
      {
        title: "大纲",
      },
    ];
    this.step1 = {
      majorName: "",
      keywords: [],
      type: "",
      canSubmit: false,
      autoLightTypes: [],
    };
    this.step2 = {
      selectIdx: -1,
      focusIdx: -1,
      titles: [],
    };
    this.step3 = {
      focusIdx: -1,
      menus: [],
    };
    this.step4 = {
      outlines: [],
      selectIdx: -1,
      focusIdx: -1,
      titles: [],
    };
    this.stepWenxian = {
      selectIdxList: [],
      wenxianList: [""],
      // wenxianList: [
      //   "",
      //   ...[
      //     {
      //       id: "1854874938073079810",
      //       authors: "刘海霞 李家玉",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgic9X9Rw-mEvurg01s-OQkf1ShIxKxRK8jHmLX0H9p9iVwaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "内蒙古农业大学学报(哲学社会科学版)",
      //       publishDate: "2024-11-08 17:51",
      //       title: "新质生产力的四维特质及实现进路 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgz6QRY1Q_kR-aMMqwVwLUMzcvfTjQoLneUxXWQgQq4VWxtk5cMTGlHGUJJ27q42aSZyu28jyjMxPe2WWhgc86yvw78PFigv7pBoG4v3MhXyuRZ6BrMhCE-qguVGbQu9aRHbeLHrlRpgkJPqS5X2ZuuDTrUjdE0dgaxTCqRyzNGm2OZKsL9mjwBH&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //     {
      //       id: "1854874938073079811",
      //       authors: "徐佳莉 徐俊",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgifoqKvrgcKV5tFJVGNd_oIMoSeC6R7hcWKRoM3UbtA-aAaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "甘肃开放大学学报",
      //       publishDate: "2024-11-08 17:49",
      //       title:
      //         "农村家庭养老中的子女性别差异研究述评——基于CNKI数据库的可视化分析 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgznYWK9YCeTJANdOmCiWRbVZc0iQen52hjtCeDzGLzSZJn5u3ZsnE6AV160mIlZocM8zaZ-l843vhC7LxRN1k110UKej5wuDxgPoZibOOsqgMMMWWEjuyCrOtrz5TC-r-hIPXIDEYEcp0IHaplrYSFGRjd3EmVpwFkSFDn9dLKFJepig8P8aVp-&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //     {
      //       id: "1854874938073079812",
      //       authors: "刘丹 陈秋颖 马乃毅",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgid9e93eqgThMBjCb5t2IN_93D__JbdLYLLK-djRynHxYwaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "海洋开发与管理",
      //       publishDate: "2024-11-08 17:43",
      //       title:
      //         "“蓝色粮仓”战略背景下渔业数字化水平测算与建设路径研究 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgznYWK9YCeTJANdOmCiWRbVZc0iQen52himSKOxkudzoJPoZg_fHr9UDzrR3pL9B7lDSGmR5E8luc9U6lM2L51eSW1ZHxzsd9rjEUL6FBtQ6b5Y0hgCHtNlCnBRC2ogf5PFUkK8zF7nhGcaiwqPssXxLbPmnQi7k70553QNQ69el09qcHm2GiPw&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //     {
      //       id: "1854874938073079813",
      //       authors: "佘敏楚楚 管丽媛 俞建勇 丁彬 李召岭",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgifmymF3EMS8CFWG0HMlLKQ7Q_vF5poPM9sBpfo7yl9OwQaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "东华大学学报(自然科学版)",
      //       publishDate: "2024-11-08 17:42",
      //       title: "新质生产力驱动下纺织行业的创新发展与转型研究 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgxwLx5wZQXULWrrRy2uQ7ofoqURQ_P_0uYk-7MoNrrmBnft0DELboUyjD7Ty3oc0aqxS3reAVM7pKRM-oSQBsfdKDDuIxWKjryEF3My-S85lmK2QT0KZ0_BOHyZ7D2-COTp6fwVJIdxAnwHY1OIewvNZc5HaC9leB4xlv8Urn8vi5uz0seEpwoE&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //     {
      //       id: "1854874938073079814",
      //       authors: "韩文龙 康灵烁 张瑞生",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgifeJXVg1vMy2QdSv2U00-a6hRwx3cnHOF3MpsIgE16UrQaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "中共杭州市委党校学报",
      //       publishDate: "2024-11-08 17:42",
      //       title: "生成式人工智能对就业的三重效应及其应对 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgxwLx5wZQXULWrrRy2uQ7ofoqURQ_P_0uZRwSt90vZx3fW58rnbvpcqmSyKhRb4xJHmsjmHwLm1HV8kSgfo3KJvFLR3Z_3djLoHudrDqITmTpYLNfjx4tRN9kueYyQsL7elmSeL3OY7VBRjjZcwLv5RSkiI-kjrAFjSwyvgsiRHh94DXwMtDq-E&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //     {
      //       id: "1854874938073079815",
      //       authors: "杨宗凯",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgie2vBaSCE8AnnJD6yMt_4VO3oa_0o8JdVX89b9ALqiLFwaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "中国远程教育",
      //       publishDate: "2024-11-08 17:40",
      //       title: "秉持“3I”新理念纵深推进教育数字化 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgxwLx5wZQXULWrrRy2uQ7ofoqURQ_P_0ualufOAteee_cL1CDDIF-l2i8i9raVnnwamSUDVuZYlnKOO-oP7uMLZqnpOj6NmH5B4ZVDjbszXhAdqqGnEVpL_HYfOtUANLVAnoDVlmLSmuI0vaH_FW-vutswexOuCVIQDi4PQzRKRPihZX_11AKBL&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //     {
      //       id: "1854874938073079816",
      //       authors: "Zamira Hyseni Duraku;Genta Jahiu;Donjeta Geci",
      //       citationCount: "",
      //       cite: "0CtkZmp-46N1SzCtT4NW3AM1UHjV0uMR_icN4IXwgichTTXQUT3Ku72CIqniNkhrzIe9GQT9R2Rl3zLOOUPDwhZ3CfQWa9X92fxVsy8SfGm4tPxisrPMKgTmbn3h1RRZfSAiTad_wS3O0lFzVSUcrw!!",
      //       publication: "International Journal of Educational Reform",
      //       publishDate: "2024-11-04",
      //       title:
      //         "The Interplay of Individual and Organizational Factors With Early Childhood Teachers’ Level of Work Motivation, job Satisfaction, and Burnout 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgy9lukE0Jzys0PMZWw7hMEIeEu0UE98QUqdhuIbGO9yHlIB9EK5ZgQWKgHfNXuyfsNskdAC9jKYzIsVngUGA-Mc_2H_x8cynxld1pErEjCg-6HPhiSVmnYDamG23vvwtt3mhcf1UG6Dh5wduuSfZ4ivDlr0DcIVAWjWvvb0F7K4bL3K0Q1roIC176asWKLn39J2z16AizuMbM4LDaxKAVLX&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 1,
      //     },
      //     {
      //       id: "1854874938073079817",
      //       authors: "Ella Poutiainen",
      //       citationCount: "",
      //       cite: "0CtkZmp-46N1SzCtT4NW3AM1UHjV0uMR_icN4IXwgichTTXQUT3Ku72CIqniNkhrEk0JQA_9CN3UWE0846lg6OnOT6IYCfDkTo8wYJ11i7KxIrH3xD59hfOsm8eYc4wefSAiTad_wS3O0lFzVSUcrw!!",
      //       publication: "European Journal of Cultural Studies",
      //       publishDate: "2024-11-04",
      //       title:
      //         "A feminism of the soul? Postfeminism, postsecular feminism and contemporary feminine spiritualities 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgy9lukE0Jzys0PMZWw7hMEIeEu0UE98QUqdhuIbGO9yHvXJLiggPcRIvjQZ7brjubP7G3HAObi-acB8CsZLX0vhlB58nt8yj5iTa2UtbOJ2wJV0gS01doM2miHk5GEXR9S6kALLuW8cz-_wd-OmXg414TU6FnONoKnzpkrj57yqHSe2d9Ggz0y3KG716d_-E4DAl6gf2euKsWEd8xgJrYw8&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 1,
      //     },
      //     {
      //       id: "1854874938073079818",
      //       authors: "Itoiz Rodrigo Jusué",
      //       citationCount: "",
      //       cite: "0CtkZmp-46N1SzCtT4NW3AM1UHjV0uMR_icN4IXwgichTTXQUT3Ku72CIqniNkhr7PHY5STCYkRYzZN7UxnR_FxO2j_MTJqa48gSHiHIEbjNv081DnzaqyRwuInG7xRufSAiTad_wS3O0lFzVSUcrw!!",
      //       publication: "European Journal of Cultural Studies",
      //       publishDate: "2024-11-04",
      //       title:
      //         "‘It’s like almost hypnotised people’: An exploration of vernacular discourses and social imaginaries of terrorism in the United Kingdom 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgzjRfVqnzzlrjxdIzmpXN9mHbBfp91pQITlI8dZC7J3uzKz4v8gI3nicGlMs3VVDpt3Z9-UQJkn0KV-r0uqYbIfSE6mrGw1n2ibYSD_PxMrZBmGV_TjHByBTP3Y1lRDaqdC6_2F6NDOFdD1J-nxEJBLfyFiPt8OlwGd3c7d2KHEC5pqC7k4NJUw-Ns3roYdr6WTr_2-imuzeA_W_gwsg3Mt&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 1,
      //     },
      //     {
      //       id: "1854874938073079819",
      //       authors: "Hasan Akın",
      //       citationCount: "",
      //       cite: "0CtkZmp-46N1SzCtT4NW3AM1UHjV0uMR_icN4IXwgicia_HbWwrwnERDHZXjYzlxHFnR8MD-KizBRGvIoIs1HrJnnBUX8w3Q52ClKoNY7Z-dtO65dEoNzUXnx2coxwH4fSAiTad_wS3O0lFzVSUcrw!!",
      //       publication: "Physica Scripta",
      //       publishDate: "2024-11-04",
      //       title:
      //         "Phase transition analysis of the Potts-SOS model with spin set {−1,0,+1} on the Cayley tree 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgzjRfVqnzzlrjxdIzmpXN9mHbBfp91pQIQnjn-ut-MNKzW-nLKdl9OH9dMPW-6kGCD87GVklCi7rbje3MtltFGRlD--F6eZMRjiRsPGMIxOrLbmtL7qLfABsrJrmORcuCrRMdV-YGGUbekhCoXFvkv5U_ZM3BDhx0vK6li4OkX7zaD75iMJagTk24QgaI76CqL88LWQjjbZELL9W9u5kZ3v&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 1,
      //     },
      //     {
      //       id: "1854874938077274113",
      //       authors: "Jinho Bang;Daeik Jang;Beomjoo Yang",
      //       citationCount: "",
      //       cite: "0CtkZmp-46N1SzCtT4NW3AM1UHjV0uMR_icN4IXwgicia_HbWwrwnERDHZXjYzlxCdhEA5PGg6IOfRCnZ4Ibi744R0zAPtyQgpE7ynsa0G6460RqjTjXHvc3xJCEct-YfSAiTad_wS3O0lFzVSUcrw!!",
      //       publication: "Functional Composites and Structures",
      //       publishDate: "2024-11-04",
      //       title:
      //         "Cyclic loading effects on the heat-generation performance of carbon nanotube-embedded cement composites 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgzjRfVqnzzlrjxdIzmpXN9mHbBfp91pQIQnjn-ut-MNK45awqnC0F4ameadHmfm4NfpIkY808m0GoiHFCKgV6YBTsuxsLz6tbsgDfwOpaCD3LvVpNQ_DYpp67LkNIf_ZsRLprrQt-SdS0JzOHaF9Lv6dXbnTNZyWfvrJW8D7nSYv3zrJCnA_IeQHPJPB3j7RcLqhX9wpLON39TrXmQ6uWV4&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 1,
      //     },
      //     {
      //       id: "1854874938077274114",
      //       authors: "陈晓洁",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgifw9A2IFLWMJMOOhk9SWq8-40JLmrE8WTaXP7ajP0ZxzAaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "科学学研究",
      //       publishDate: "2024-11-08 17:38",
      //       title:
      //         "如何缓解“融资贵”难题？——基于中小银行与科技公司战略合作的新视角 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgxA2Nmeu_q0kvUI30v9janMxPZapjpo40dBNb47CYDOtfgAuNr-KPhUJqHXiMfkYJAWq2qBsS9-J9EU1fVuOyovCK2Cbnyh7uA50bVleaN7bykShzGScKgW0c47Bf7a26PH3O2w9iZpKyCHPRu4iscN3ypXXsyf_YA3SIL0cD520_qjLDI7NEUx&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //     {
      //       id: "1854874938077274115",
      //       authors: "徐文彬 李琳 杨茜",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgieI3mmp9OlEt9OcvLrwny2PRsVOSB2h-bpb4wr7ei3MygaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "护士进修杂志",
      //       publishDate: "2024-11-08 17:34",
      //       title: "患者自我倡权评估工具的范围综述 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgxA2Nmeu_q0kvUI30v9janMxPZapjpo40epDRQFe0hoeOgDaALcUjJ1rxRwAa_W1Go6T7a8-Q7SrO0z0QQ5k159HB1zcmizAfgHsXVgkv9j0DYh4xvBp23hYXjoZlB0wpqvfDvVxLAj-2a1xAXgGZXzS1Q7fFnuKtJgf8ovlOI6YRoYRb4bS5Rh&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //     {
      //       id: "1854874938077274116",
      //       authors: "胡力芹 刘维 张帆 刘瑞芳 郝茹 王国位",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgidQ3TO9czKYrtpWQ8GFD37zc-TuDznoHUgNbTODCYcoPwaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "中国循证心血管医学杂志",
      //       publishDate: "2024-11-08 17:33",
      //       title:
      //         "高原青年人群与平原中老年肺栓塞临床特点比较及防治策略 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgw1I2btU5iyVL8U3bVCB14BuEaCha3f4K-Z78sSWGv-nKI4DULdU9sBNVKQI08rzexFuLubnxfB4vuUUuUAvjLVRWrSxUDRvY0eUADb8_kej9iDQyLEiU4Vae5NAPaJtlqCd7CfuZJCbZTdSJ8-R3vMuOqd1EvmEdJcdyhpvbKIHGnfvqtOFtPL&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //     {
      //       id: "1854874938077274117",
      //       authors: "欧佳乐 昝红英 许鸿飞",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgidl-bo-w4Fm5I0Tj3Z57aWY29QniGG1bdS-4vdd9o1jtQaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "小型微型计算机系统",
      //       publishDate: "2024-11-08 17:31",
      //       title: "基于多模态联合建模的端到端语音到文本翻译 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgw1I2btU5iyVL8U3bVCB14BuEaCha3f4K8LWGqNvRlZrbFcXut26FNmaNfn3A2-sxam51qrx-COIUCZBw0e6qq7zQeUljWiMLGlWvh3ZtE5SXyP5ipGIxfrr9nturV3WJcEQ1zcYNBzuqdT3K0jxXVDO8POYr62Uau2Sko3Ot0pFnWdWoMR-61r&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //     {
      //       id: "1854874938077274118",
      //       authors: "周拥志 彭筱平 赵彬 夏小鹏",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgidQ3TO9czKYrtpWQ8GFD37zUBwtCtMFaDBi0QrfBMgA1AaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "中国循证心血管医学杂志",
      //       publishDate: "2024-11-08 17:23",
      //       title: "青年女性多囊卵巢综合征并发急性心肌梗死1例 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgyBl0XFnHk8ngiXoi7VqabILaLPQWL4l1IbUz9EfzGiNjF-1Ps1eLhZ-ubsBfMYF6aD34DhnarFCYWHIzHGbFl6OV4_NlLVHhoPsXYoAB7RbaJ5tEX8UR6X9TmReXqtjzPxPZePeia0HCGXwIU80ocmrbQxz_s2ZXvxV5TqhKjmAW69FszyuZfm&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //     {
      //       id: "1854874938077274119",
      //       authors: "刘星辰",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgidOLCr2qyn-XZLFqjE3KsNNDGJR8OUfJDqTVfz7YjAZzgaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "教育理论与实践",
      //       publishDate: "2024-11-08 17:15",
      //       title:
      //         "新时代高校通识舞蹈教育创新维度探索——评《高校通识舞蹈教育与舞蹈艺术研究》 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgyBl0XFnHk8ngiXoi7VqabILaLPQWL4l1J-2Dh5r39vjQs6e4rO0QlKSXbaK9vvykbQf6kWW-KXKlk4aDvxyflgZiWhXFb58BYJbgPAtC5TWqJopDd0GD6riG8DJXg-nbMq7wQt3bsDKfsg_jL5Zr8eW-GeCJ925WXZWC72eEjbLg8VqoHKSHrY&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //     {
      //       id: "1854874938077274120",
      //       authors: "高进 黄种兴 刘聪",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgifw9A2IFLWMJMOOhk9SWq8-NoweV6n4hu8TfqSfSMb8PgaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "科学学研究",
      //       publishDate: "2024-11-08 17:15",
      //       title: "预印本文化对缓解科研可重复性危机的作用机制 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgxOLJzcok0eyPqJuoTSDnuc0DIasruHssX5l09-mUQmSIHJLN_DKY3G_WiEi0YjF19ylZu_8L73vt8CVPrtGh_jjNg8R7kCgR8bHksTMifa4MVsRoRKaFgUZ2O_40_mgSLpBeQuGQLFo4sClC7ARo47E8bSbJOSqG-OLM-LQx0ezb7JbN4Q9n2u&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //     {
      //       id: "1854874938077274121",
      //       authors: "范月圆 兰惠 崔宁波",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgiemBEDesEbzo8tHHRHJIJwRPakfPi_H-9XBZ1m5WjDmagaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "中国农业大学学报(社会科学版)",
      //       publishDate: "2024-11-08 17:14",
      //       title:
      //         "以新质生产力赋能粮食产业高质量发展:理论逻辑与实践指向 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgxOLJzcok0eyPqJuoTSDnuc0DIasruHssWFL0EOASMuDrj1-VczgtcSo0I7yaCob3QU_dieNALvRjPR9XkxYT2n2jPvADYquwXzKjrH3jGzNstUBJdevyGcaYQCDp6pb_PI2jlQ5I6XtwXLI0v94MJzYKtBI4LhY1kdQy2yMmVeMWBXKC12nOrO&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //     {
      //       id: "1854874938077274122",
      //       authors: "李乐虎 王姗姗 刘玉财",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgiegRIPO9rauA1rYl3ff6k_VcU3Tm2-VYlW476g5RvJUyQaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "沈阳体育学院学报",
      //       publishDate: "2024-11-08 17:13",
      //       title:
      //         "我国青少年体育教育政策衔接的主要类型、共性问题与实现路径 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgxOLJzcok0eyPqJuoTSDnuc0DIasruHssXYpQjwOKrVq837z64jKEEkCgvZyhTIqGbfuwtX93wfEE1Gh7BaNPVO6a4-Gzer1ixQAYZXmuFbsNB5PnlLioSNZDLZ519MBf9FVmtZkMojwOPfLrM_JwJDD6-5lHA6_5K9in1dlwpoXGo4PPs4-1bu&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //     {
      //       id: "1854874938077274123",
      //       authors: "曹苏方 张业安 于晓梅",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgiegRIPO9rauA1rYl3ff6k_V-gcKsAW_TBWm2554JSsHuAaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "沈阳体育学院学报",
      //       publishDate: "2024-11-08 17:13",
      //       title:
      //         "回归现实与身体:数字时代体育运动的意义与具身路径 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgw8uABZz33kGXQyT_DqFeZZKYAAffN81bK6jqwOo38iZ-CrwY15eiBHZ4bZlJVr1EpA4ShxNjZCOGUHEHDvHmx2PuiUQIZiL-NkcM3dr4ySiL8SQpQg6HzZxju7BzfdQm2dZAnyeSIf2VJiAR1JZjfKCILf3WmgSSoKWyma9DvNxyq7eBnWdtYM&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //     {
      //       id: "1854874938077274124",
      //       authors: "禹华森 李利强 李菲菲 汪晓赞",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgiegRIPO9rauA1rYl3ff6k_Vqt-6Rgm1QQOhliMSNjHeAAaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "沈阳体育学院学报",
      //       publishDate: "2024-11-08 17:12",
      //       title:
      //         "体育与健康课程大单元教学的历史溯源、本质特征及实践路径 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgw8uABZz33kGXQyT_DqFeZZKYAAffN81bK6jqwOo38iZ-CrwY15eiBHl671O7y5KFVussjflvg6SKGtkUfZYI6jFiSZ0kzxaDvtkvO9rlTgLzbA5RIR4eDMTVWOJM3K_wdV4ftG0UQ8jWL4gACEuYsT73OvpesjsCXJ_e-i114UsffQ2L8pAkCC&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //     {
      //       id: "1854874938077274125",
      //       authors: "牟仁德 丁宁 刘冠熙 罗宇晴 戴建伟",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgifqmO6V8BLFNEmLYyx-BjZ_1E1u-k1IcdUkw307XwyjAwaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "材料工程",
      //       publishDate: "2024-11-08 17:12",
      //       title:
      //         "不同电子束流EB-PVD制备YSZ热障涂层的组织结构演变、热循环寿命与失效行为分析 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgx2MPB17xFttJ0p0uanzTVdAsCTmw8wo8IHe_9l-R2ff76hejKqwJJshNV1VWkQGRMboQkYQtd6xwLbF3vvj5nlSzPbxIAHsXlBipBKo2mgbPp0hflj5E6Jhw1-1v65toTHc6yRcOy-xdO0euNcBfs-hUvu7ApdSMd_Pizp2_I3b54fqvgeCclJ&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //     {
      //       id: "1854874938077274126",
      //       authors: "史心琦 马梦梦 高腾云 刘深贺",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgicNYzj7YaBrQGq_T_jl8w22idkZeaeUQ_WGf9nPXJ_MZgaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "畜牧兽医学报",
      //       publishDate: "2024-11-08 17:10",
      //       title: "动物肠道微生物调控精液品质的研究进展 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgw4Uh3N47A62_GLxH3_bHyp3vh0HzxxW4MFAq9SJ98S1l-vwpq1VOZWU40PMRRpx-MJCntuJnuLh4zx2S6R-MrWZ0PLsF-svtxYmS9PvIHDrdpyyCl0IzuBms82REzZsOK3e4w3mFhH2aMk037YvPBIetcKow8EVocNn2X0YgUH6-PMmMzGguZE&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //     {
      //       id: "1854874938077274127",
      //       authors: "李俊欣 周子涵",
      //       citationCount: "",
      //       cite: "kd9kqNkOM8Xyu_MccKCQ5AM1UHjV0uMR_icN4IXwgifI4xQMlzFiZ1qVUIC9h2HMndX2VGdWJxflBolpfa1vewaBrY7S9XkpoIwjfAktEnKX8H-zThafoXFu3hRPWoVX",
      //       publication: "电子科技大学学报(社科版)",
      //       publishDate: "2024-11-08 17:06",
      //       title:
      //         "数字游戏推动中华优秀传统文化国际传播的实践进路——以《黑神话：悟空》为例 网络首发 免费",
      //       url: "http://222.211.73.233:8093/kcms2/article/abstract?v=D99zBTMMfgw4Uh3N47A62_GLxH3_bHyp3vh0HzxxW4OAbjlWL2DT4DYHBfP5nkOV5edyq7umdfEYR--MJmX2WDkzDcKajd3oyvw6FYR8tQesTkMaPRghVx2f1kfnv_Md87I23VOyehASZc5W4hIf_bwdZdjGOoqz7s3qnGuUJfM2CJZCVkPIBRAFshb9HGnK&uniplatform=NZKPT&language=CHS",
      //       cite2: null,
      //       content: null,
      //       paperCn: 0,
      //     },
      //   ],
      // ],
    };
  }

  async step1Submit() {
    this.nextLoading = true;
    this.step2.selectIdx = -1;
    this.step2.focusIdx = -1;
    try {
      const res = await post("/ai/paper/gen/title", {
        majorName: this.step1.majorName,
        keywords: this.step1.keywords.join(","),
        type: this.step1.type,
      });
      this.step2.titles = ["", ...res.data];
      this.nextLoading = false;
      this.curStep = 1;
    } catch (error) {
      this.nextLoading = false;
    }
  }
  async step2Submit() {
    this.nextLoading = true;
    try {
      const res = await post("/ai/paper/r/list", {
        // majorName: this.step1.majorName,
        keywords: this.step1.keywords.join(","),
        // paperTitle: this.step2.titles[this.step2.selectIdx],
      });
      this.stepWenxian.wenxianList = ["", ...res.data];
      // const res = await post("/ai/paper/gen/abstracts", {
      //   majorName: this.step1.majorName,
      //   keywords: this.step1.keywords.join(","),
      //   paperTitle: this.step2.titles[this.step2.selectIdx],
      //   ll: this.getll(),
      //   ls: this.step1.ls ? "y" : "n",
      // });
      // this.step4.titles = [res.data];
      this.nextLoading = false;
      this.curStep = 2;
    } catch (error) {
      this.nextLoading = false;
    }
  }
  async moreStep2() {
    try {
      this.nextLoading = true;
      const res = await post("/ai/paper/gen/abstracts", {
        majorName: this.step1.majorName,
        keywords: this.step1.keywords.join(","),
        paperTitle: this.step2.titles[this.step2.selectIdx],
        ll: this.getll(),
        ls: this.step1.ls ? "y" : "n",
        type: this.step1.type,
      });
      // TODO: MOCK
      this.step4.titles.push(res.data);
      this.nextLoading = false;
    } catch (err) {
      this.nextLoading = false;
    }
  }
  async stepWenxianSubmit() {
    try {
      if (
        !newConstructStore.stepWenxian.selectIdxList.some((idx) => idx !== 0)
      ) {
        message.info("请至少选择一篇推荐文献");
        return;
      }

      this.nextLoading = true;
      let customReferenceDocument = undefined;
      const reference = this.stepWenxian.selectIdxList
        .map((id) => {
          if (id > 0) {
            return this.stepWenxian.wenxianList[id].id;
          } else {
            customReferenceDocument = this.stepWenxian.wenxianList[0];
            return "";
          }
        })
        .filter(Boolean)
        .join(",");
      const res = await post("/ai/paper/gen/abstracts", {
        majorName: this.step1.majorName,
        keywords: this.step1.keywords.join(","),
        paperTitle: this.step2.titles[this.step2.selectIdx],
        ll: this.getll(),
        ls: this.step1.ls ? "y" : "n",
        type: this.step1.type,
        reference,
        customReferenceDocument,
      });
      this.step4.titles.push(res.data);
      this.nextLoading = false;
      this.curStep = 3;
    } catch (err) {
      this.nextLoading = false;
    }
  }
  async step3Submit() {
    try {
      this.nextLoading = true;
      // const res = await post("/ai/paper/gen/outline", {
      const res = await post("/ai/paper/gen/new/outline", {
        majorName: this.step1.majorName,
        keywords: this.step1.keywords.join(","),
        paperTitle: this.step2.titles[this.step2.selectIdx],
        paperAbstracts: this.step4.titles[this.step4.selectIdx],
        type: this.step1.type,
        autoLightTypes: this.step1?.autoLightTypes
          ? this.step1.autoLightTypes.join(",")
          : "",
      });
      this.step3.menus = res.data.map((d) => ({
        id: uniqueId("step3"),
        ...d,
      }));
      this.step4.outlines = [...res.data];
      this.nextLoading = false;
      this.curStep = 4;
    } catch (error) {
      this.nextLoading = false;
    }
  }
  async step4Submit() {
    try {
      this.nextLoading = true;
      let customReferenceDocument = undefined;
      const reference = this.stepWenxian.selectIdxList
        .map((id) => {
          if (id > 0) {
            return this.stepWenxian.wenxianList[id].id;
          } else {
            customReferenceDocument = this.stepWenxian.wenxianList[0];
            return "";
          }
        })
        .filter(Boolean)
        .join(",");
      // 存储选中的类型
      let ss = [];
      if (this.step1?.autoLightTypes?.length === 0) {
        ss = "0";
      } else {
        if (this.step1?.autoLightTypes?.includes("TABLE")) ss.push("1");
        if (this.step1?.autoLightTypes?.includes("FORMULA")) ss.push("2");
        if (this.step1?.autoLightTypes?.includes("DIAGRAM")) ss.push("3");
        if (this.step1?.autoLightTypes?.includes("CODE")) ss.push("4");
      }
      const res = await post("/ai/paper/new/w", {
        majorName: this.step1.majorName,
        keywords: this.step1.keywords.join(","),
        paperTitle: this.step2.titles[this.step2.selectIdx],
        paperAbstracts: this.step4.titles[this.step4.selectIdx],
        // paperOutline: [
        //   ...this.step3.menus.map((m) => ({
        //     chapter: m.chapter,
        //     subheadings: m.subheadings,
        //   })),
        //   {
        //     chapter: `${
        //       newConstructStore.step1.type === 1
        //         ? nzhcn.encodeS(this.step3.menus.length + 1) + "、"
        //         : ""
        //     }参考文献`,
        //     subheadings: [],
        //   },
        // ],
        paperNewOutline: [
          ...this.step4.outlines,
          {
            chapter: `${
              this.step1.type === 1
                ? nzhcn.encodeS(this.step4.outlines.length) + "、"
                : ""
            }参考文献`,
            subheadings: [],
          },
        ],
        type: this.step1.type,
        reference,
        customReferenceDocument,
        // lv: this.step1.type === 1 ? 3 : 0,
        ss: Array.isArray(ss) ? ss.join(",") : ss,
        orderNo: null,
      });
      // this.nextLoading = false;
      const paper =
        this.paperTypes.find((it) => it.value == this.step1.type) || {};
      console.log("xx", paper);
      return { ...res.data, paperItem: paper };
    } catch (error) {
      this.nextLoading = false;
      throw error;
    }
  }
  goLast() {
    this.curStep--;
    if (this.curStep < 0) {
      this.curStep = 0;
    }
  }
  goFinish() {}

  step1Change(_, b) {
    const { majorName, keywords, ls, type, autoLightTypes } = b;
    this.step1.majorName = majorName;
    this.step1.keywords = keywords;
    this.step1.type = type;
    this.step1.autoLightTypes = autoLightTypes;
    if (majorName && keywords.length > 0 && type) {
      this.step1.canSubmit = true;
    } else {
      this.step1.canSubmit = false;
    }
    this.step1.ls = ls;
  }
  step2Foucs(i) {
    this.step2.focusIdx = i;
  }
  step2Select(i) {
    this.step2.selectIdx = i;
  }
  step2Change(i, v) {
    this.step2.selectIdx = i;
    this.step2.titles[i] = v;
    if (!v) {
      this.step2.selectIdx = -1;
    }
  }
  stepWenxianChange(i, v) {
    const idx = this.stepWenxian.selectIdxList.indexOf(i);
    if (idx === -1) this.stepWenxian.selectIdxList.push(i);
    this.stepWenxian.wenxianList[i] = v;
    if (!v && idx > -1) {
      this.stepWenxian.selectIdxList.splice(idx, 1);
    }
  }
  stepWenxianSelectAll(checked) {
    const systemWenxianListIndexs = this.stepWenxian.wenxianList.map((it,i)=> i).filter(
      (it, i) => i !== 0
    );
    if (checked) {
      console.log('systemWenxianListIndexs', systemWenxianListIndexs);
      this.stepWenxian.selectIdxList = systemWenxianListIndexs;
      return;
    }
    this.stepWenxian.selectIdxList = this.stepWenxian.selectIdxList.includes(0)
      ? [0]
      : [];
  }
  stepWenxianSelect(i) {
    const idx = this.stepWenxian.selectIdxList.indexOf(i);
    if (idx > -1) {
      this.stepWenxian.selectIdxList.splice(idx, 1);
    } else {
      this.stepWenxian.selectIdxList.push(i);
    }
  }
  step3Foucs(i) {
    this.step3.focusIdx = i;
  }
  step3DragEnd(result) {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      this.step3.menus,
      result.source.index,
      result.destination.index
    );
    this.step3.menus = items;
  }
  step3Del(i) {
    this.step3.menus.splice(i, 1);
    this.step3.menus = [...this.step3.menus];
  }
  step3Add() {
    this.step3.menus.push({ id: uniqueId(), chapter: "", subheadings: [""] });
  }
  step3DelSub(i, iv) {
    this.step3.menus[i].subheadings.splice(iv, 1);
    this.step3.menus = [...this.step3.menus];
  }
  step3AddSub(i, iv) {
    this.step3.menus[i].subheadings.splice(iv + 1, 0, "");
    this.step3.menus = [...this.step3.menus];
  }
  step3Change(i, v) {
    this.step3.menus[i].chapter = v;
    this.step3.menus = [...this.step3.menus];
  }
  step3ChangeSub(i, j, v) {
    this.step3.menus[i].subheadings[j] = v;
    this.step3.menus = [...this.step3.menus];
  }
  step4Foucs(i) {
    this.step4.focusIdx = i;
  }
  step4Select(i) {
    this.step4.selectIdx = i;
  }
  step4Change(i, v) {
    this.step4.selectIdx = i;
    this.step4.titles[i] = v;
    if (!v) {
      this.step4.selectIdx = -1;
    }
  }
  getll() {
    if (this.ll > 20) {
      this.ll = 0;
    } else {
      this.ll = this.ll + 1;
    }
    return this.ll;
  }

  // 大纲页面
  addTitle(opts) {
    if (this.isLoadingPreview) {
      message.info("论文预览生成中，请结束后再试");
      return;
    }

    const {
      level,
      level1idx,
      level2idx,
      level3idx,
      inputMain,
      inputSub,
      isAfter,
    } = opts;
    let idxDiff = isAfter ? 1 : 0;
    if (level === 1) {
      this.step4.outlines.splice(level1idx + idxDiff, 0, {
        chapter: inputMain,
        sections: [],
      });
    } else if (level === 2) {
      this.step4.outlines[level1idx].sections.splice(level2idx + idxDiff, 0, {
        chapter: inputMain,
        chapterAbstract: inputSub,
        extraType: "NORMAL,CODE,TABLE,DIAGRAM,FORMULA",
        selected_extra: "",
        subsections: [],
      });
    } else if (level === 3) {
      this.step4.outlines[level1idx].sections[level2idx].subsections.splice(
        level3idx + idxDiff,
        0,
        {
          chapter: inputMain,
          chapterAbstract: inputSub,
          extraType: "NORMAL,CODE,TABLE,DIAGRAM,FORMULA",
          selected_extra: "",
        }
      );
    }
  }

  editTitle(opts) {
    // if (this.nextLoading) {
    //   message.info("论文预览生成中，请结束后再试");
    //   return;
    // }

    const { level, level1idx, level2idx, level3idx, inputMain, inputSub } =
      opts;
    if (level === 1) {
      this.step4.outlines[level1idx] = {
        ...this.step4.outlines[level1idx],
        chapter: inputMain,
      };
    } else if (level === 2) {
      this.step4.outlines[level1idx].sections[level2idx] = {
        ...this.step4.outlines[level1idx].sections[level2idx],
        chapter: inputMain,
        chapterAbstract: inputSub,
      };
    } else if (level === 3) {
      this.step4.outlines[level1idx].sections[level2idx].subsections[
        level3idx
      ] = {
        ...this.step4.outlines[level1idx].sections[level2idx].subsections[
          level3idx
        ],
        chapter: inputMain,
        chapterAbstract: inputSub,
      };
    }
  }

  deleteTitle(opts) {
    // if (this.nextLoading) {
    //   message.info("论文预览生成中，请结束后再试");
    //   return;
    // }

    const { level, level1idx, level2idx, level3idx, txt } = opts;
    Modal.confirm({
      title: "确认删除吗",
      content: `确认删除章节<${txt}>吗？章节不足可能会导致论文总体字数不足`,
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        if (level === 1) {
          this.step4.outlines.splice(level1idx, 1);
        } else if (level === 2) {
          this.step4.outlines[level1idx].sections.splice(level2idx, 1);
        } else if (level === 3) {
          this.step4.outlines[level1idx].sections[level2idx].subsections.splice(
            level3idx,
            1
          );
        }
      },
      onCancel: () => {},
    });
  }
  changeTitleExtra(opts) {
    return;
    // if (this.nextLoading) {
    //   message.info("论文预览生成中，请结束后再试");
    //   return;
    // }

    const { v, level, level1idx, level2idx, level3idx } = opts;
    const { checked, type } = v;
    if (level === 1) {
    } else if (level === 2) {
      const origin =
        this.step4.outlines[level1idx].sections[level2idx].selected_extra;
      this.step4.outlines[level1idx].sections[level2idx] = {
        ...this.step4.outlines[level1idx].sections[level2idx],
        selected_extra: modifySelectExtra({ origin, checked, type }),
      };
    } else if (level === 3) {
      const origin =
        this.step4.outlines[level1idx].sections[level2idx].subsections[
          level3idx
        ].selected_extra;
      this.step4.outlines[level1idx].sections[level2idx].subsections[
        level3idx
      ] = {
        ...this.step4.outlines[level1idx].sections[level2idx].subsections[
          level3idx
        ],
        selected_extra: modifySelectExtra({ origin, checked, type }),
      };
    }
  }
}

function modifySelectExtra(opt) {
  const { origin, checked, type } = opt;
  const originList = origin?.split(",") || [];
  const isCheckedBefore = originList.includes(type);
  if (!isCheckedBefore && checked) {
    originList.push(type);
    return originList.join(",");
  }
  if (isCheckedBefore && !checked) {
    const idx = originList.indexOf(type);
    originList.splice(idx, 1);
    return originList.join(",");
  }
  return origin;
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
export const newConstructStore = new NewConstructStore();
